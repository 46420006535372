import React from 'react';
import Logo from '../assets/lgologowhite.svg';
import {Link} from 'react-scroll';

const Header = () => {
  return (
  <header className='py-8'>
  <div className='container mx-auto'>
  <div className='flex justify-between items-center'>
    <a href='#'>
      <img src={Logo} className='h-[100px]' alt='logo'/>
    </a>
    <Link to='contact' activeClass='active'
      smooth={true}
      spy={true} 
    >
    <button className='btn btn-sm'>Zapytaj o termin</button>
    </Link>
  </div>
  </div>
  </header>
  );
};

export default Header;
